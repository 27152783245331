@import url(https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
    font-family: 'Montserrat', sans-serif;
}

body {
  font-family: 'Anek Malayalam', sans-serif;
  /* background: linear-gradient(90.04deg, #800080 0.03%, #ffc0cb 99.96%);
  color: #11052c;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0 30px;  */
}

.quiz-container {
  width: 50% !important;
  /* max-width: 500px; */
  min-width: 250px;
  background: #ffffff;
  border-radius: 4px;
  margin: 50px auto;
  padding: 100px 60px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: transparent;
}

.quiz-container1 {
  @media (min-width: 800px) {
    width: 800px;
    min-width: 250px;
    background: #ffffff;
    border-radius: 4px;
    margin: 50px auto;
    padding: 100px 60px;
    display: flex;
    flex-direction: column;
    background: transparent;
  }

  background: #ffffff;
  border-radius: 4px;
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
  background: transparent;
}

.parent_container {
  display: flex;
  justify-content: space-between;
  height: 98vh;
}

.parent_container img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.quiz-img-container {
  width: 50%;
  padding: 50px 0 0 0;
}

/* .quiz-container .active-question-no { */
.active-question-no {
  font-size: 32px;
  font-weight: 500;
  color: #1c6af2;
}

/* .quiz-container .total-question { */
.total-question {
  font-size: 16px;
  font-weight: 500;
  color: #e0dee3;
}

.quiz-container h2 {
  text-align: center;
  font-size: 1.8rem;
  color: #161a1f;
  line-height: 1.4;
  font-weight: 600;
  margin: 0 0 0 0;
}

.quiz-container1 h2 {
  /* text-align: center; */
  @media (min-width: 800px) {
    font-size: 1.8rem;
    color: #161a1f;
    line-height: 1.4;
    font-weight: 500;
    margin: 0 0 0 0;
  }

  font-size: 1.5rem;
  color: #161a1f;
  line-height: 1.3;
  font-weight: 500;
  margin: 0 0 0 0;
}

.quiz-container1 h4 {
  @media (min-width: 800px) {
    font-size: 1.6rem;
    color: #161a1f9c;
    line-height: 1.4;
    font-weight: 500;
    margin: 10px 0 0 0;
  }
  font-size: 1.3rem;
  color: #161a1f9c;
  line-height: 1.2;
  font-weight: 500;
  margin: 10px 0 0 0;
}

.quiz-container ul {
  margin-top: 20px;
  margin-left: -40px;
}

.quiz-container1 ul {
  margin-top: 20px;
  margin-left: -40px;
}

.quiz-container ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;

  text-decoration: none;
  list-style: none;
  color: #2d264b;
  /* font-size: 16px; */
  font-size: 1.3rem;
  /* background: #ffffff; */
  background: #f7f7f7;
  /* border: 1px solid #eaeaea; */
  border-radius: 16px;
  padding: 30px 40px;
  margin-top: 20px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 350ms ease-in-out;
}

.quiz-container1 ul li {
  @media (min-width: 800px) {
    font-size: 1.3rem;
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;

  text-decoration: none;
  list-style: none;
  color: #2d264b;
  /* font-size: 16px; */
  /* font-size: 1.3rem; */
  /* background: #ffffff; */
  background: #8ebaf133;
  /* border: 1px solid #eaeaea; */
  border-radius: 5px;
  padding: 25px 25px;
  margin-top: 20px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 350ms ease-in-out;
}

.quiz-container1 ul li .txt_index {
  border: 2px solid #0571f782;
  padding: 8px 12px;
  line-height: 1;
  text-align: center;
  border-radius: 5px;
  background: white;
}

.quiz-container ul li:hover {
  border: 1px solid #8cbeeb;
}
.quiz-container1 ul li:hover {
  border: 1px solid #0089ff;
}

.quiz-container ul .selected-answer {
  background: #fff;
  /* border: 1px solid #1363f5; */
  border: 1px solid #8cbeeb;
  box-shadow: 0 2px 6px #162a410f, 0 12px 24px #07152514;
}
.quiz-container1 ul .selected-answer {
  background: #fff;
  /* border: 1px solid #1363f5; */
  border: 1px solid #8cbeeb;
  box-shadow: 0 2px 6px #162a410f, 0 12px 24px #07152514;
}

.quiz-container button {
  background: linear-gradient(90.04deg, #1464f5 0.03%, #78aedd 99.96%);
  border-radius: 9px;
  font-size: 18px;
  color: #ffffff;
  padding: 10px 42px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 15px;
}
.quiz-container1 button {
  background: linear-gradient(90.04deg, #1464f5 0.03%, #78aedd 99.96%);
  border-radius: 9px;
  font-size: 18px;
  color: #ffffff;
  padding: 10px 42px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 15px;
}

.quiz-container button:disabled {
  background: #e7e8e9;
  color: #9fa3a9;
  cursor: not-allowed;
}
.quiz-container1 button:disabled {
  background: #e7e8e9;
  color: #9fa3a9;
  cursor: not-allowed;
}

.quiz-container .choice_div {
  width: 500px;
  max-width: 500px;
  margin: 0 auto;
}
.quiz-container1 .choice_div {
  @media (min-width: 800px) {
    width: 500px;
    /* width: 100%; */
    max-width: 500px;
    margin: 45px 0 0 0;
  }
  /* width: 500px; */
  /* width: 100%; */
  max-width: 500px;
  margin: 0 0 0 0;
}

.flex-right {
  display: flex;
  justify-content: center;
}
.result {
  padding: 50px 0 0 0;
}
.result h3 {
  font-size: 24px;
  letter-spacing: 1.4px;
  text-align: center;
  margin: 18px 0px !important;
}

.result p {
  font-size: 22px;
  font-weight: 500;
  text-align: start;
}

.result .question_div span {
  color: #131414;
  font-size: 18px;
  font-weight: 600;
}

.result .answer_div span {
  font-size: 18px;
  color: #1c6af2;
  padding: 0 0 0 15px;
}

.quiz_header {
  position: fixed !important;
  z-index: 10 !important;
  top: 0;
  right: 0;
  left: 0;
  background: #fff;
  /* height: 4.2rem; */
  box-shadow: 0px 7px 31px 0px rgba(0, 0, 0, 0.03);
  align-items: center !important;
  display: flex !important;
  flex-direction: column;
  box-sizing: border-box;
  outline: none !important;
}

.quiz_header_container {
  align-items: center !important;
  justify-content: space-between !important;
  display: flex !important;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 117rem !important;
  box-sizing: border-box;
  outline: none !important;
}

.quiz_col_2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  box-sizing: border-box;
  outline: none !important;
}

.quiz_col_auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  box-sizing: border-box;
  outline: none !important;
}

.quiz_col_2_left {
  display: flex;
  justify-content: flex-end;
  align-self: center;
}

.quiz_col_2_right {
  display: flex;
  justify-content: flex-start;
}

.quiz_p_0 {
  padding: 0 !important;
}

.quiz_back_href {
  position: relative;
  top: auto !important;
  right: auto !important;
  left: auto !important;
  font-size: 1.6rem;
  line-height: 1 !important;
  font-weight: 600;
  color: #161a1f;
  height: auto !important;
  margin-top: auto !important;
  width: auto !important;
  display: flex !important;
  z-index: 10;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  box-sizing: border-box;
  outline: none !important;
  justify-content: center;
  align-items: center;
}
.quiz_back_href:hover {
  background: #efeeee80;
  border-radius: 5px;
}

.quiz_navbar_brand {
  max-width: 18rem;
  width: 100%;
  height: auto;
  align-items: center !important;
  display: inline-flex !important;
  padding: 0 !important;
  margin: 0 !important;
  box-sizing: border-box;
  outline: none !important;
}

.quiz_brand_href {
  @media (min-width: 800px) {
    align-items: center !important;
    display: inline-flex !important;
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    transition: all 300ms ease-in-out;
    box-sizing: border-box;
    outline: none !important;
    cursor: pointer;
    font-size: 2.25rem;
    font-weight: 600;
    line-height: inherit;
    white-space: nowrap;
  }

  @media (max-width: 800px) {
    align-items: center !important;
    display: inline-flex !important;
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    transition: all 300ms ease-in-out;
    box-sizing: border-box;
    outline: none !important;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: inherit;
    white-space: nowrap;
  }
}

.quiz_step_count {
  font-size: 1.6rem;
  line-height: 1 !important;
  font-weight: 600;
  color: #161a1f;
  opacity: 1;
  justify-content: flex-end !important;
  display: flex !important;
  box-sizing: border-box;
  outline: none !important;
}

.quiz_div_fadeOut {
  opacity: 0;
  transition: opacity 1s;
}
.quiz_div_fadeIn {
  opacity: 1;
  transition: opacity 1.5s;
}

.check_div_fadeOut {
  opacity: 0;
  transition: opacity 0.3s;
}
.check_div_fadeIn {
  opacity: 1;
  transition: opacity 0.3s;
}

.progress_div {
  width: 100% !important;
}

/* ///////////////////////////////////////// mobile ///////////////////////// */

.mobile_quiz-container {
    min-width: 250px;
    background: #ffffff;
    border-radius: 4px;
    margin: 10px auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: transparent;
}

.mobile_active-question-no {
  font-size: 22px;
  font-weight: 500;
  color: #1c6af2;
}

.mobile_total-question {
  font-size: 16px;
  font-weight: 500;
  color: #e0dee3;
}

.mobile_quiz-container h2 {
  text-align: center;
  font-size: 1.6rem;
  color: #161a1f;
  line-height: 1.4;
  font-weight: 600;
  margin: 0 0 0 0;
}

.mobile_quiz-container ul {
  margin-top: 20px;
  margin-left: -40px;
}

.mobile_quiz-container ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;

  text-decoration: none;
  list-style: none;
  color: #2d264b;
  /* font-size: 16px; */
  font-size: 1.3rem;
  /* background: #ffffff; */
  background: #f7f7f7;
  /* border: 1px solid #eaeaea; */
  border-radius: 16px;
  padding: 30px 40px;
  margin-top: 20px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 350ms ease-in-out;
}

.mobile_quiz-container ul li:hover {
    border: 1px solid #8cbeeb;
}

.mobile_quiz-container ul .mobile_selected-answer {
    background: #fff;
    /* border: 1px solid #1363f5; */
    border: 1px solid #8cbeeb;
    box-shadow: 0 2px 6px #162a410f, 0 12px 24px #07152514;
}

.mobile_quiz-container button {
  background: linear-gradient(90.04deg, #1464f5 0.03%, #78aedd 99.96%);
  border-radius: 9px;
  font-size: 18px;
  color: #ffffff;
  padding: 10px 42px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 15px;
}

.mobile_quiz-container button:disabled {
  background: #e7e8e9;
  color: #9fa3a9;
  cursor: not-allowed;
}

.mobile_quiz-container .mobile_choice_div {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

.mobile_flex-right {
  display: flex;
  justify-content: center;
}

.mobile_result h3 {
  font-size: 22px;
  letter-spacing: 1.4px;
  text-align: center;
  margin: 10px 0px 0px!important;
}

.mobile_result p {
  font-size: 22px;
  font-weight: 500;
  text-align: start;
}

.mobile_result .mobile_question_div span {
  color: #131414;
  font-size: 18px;
  font-weight: 600;
}

.mobile_result .mobile_answer_div span {
 
  font-size: 18px;
  color: #1c6af2;
  padding: 0 0 0 15px;
}


.mobile_quiz_header {
    /* position: fixed!important; */
    z-index: 10!important;
    top: 0;
    right: 0;
    left: 0;
    background: #fff;
    /* height: 4.2rem; */
    box-shadow: 0px 7px 31px 0px rgba(0, 0, 0, 0.03);
    align-items: center!important;
    display: flex!important;
    flex-direction: column;
    box-sizing: border-box;
    outline: none!important;
}

.mobile_quiz_header_container {
    align-items: center!important;
    justify-content: space-between!important;
    display: flex!important;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 117rem!important;
    box-sizing: border-box;
    outline: none!important;
}

.mobile_quiz_col_2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    box-sizing: border-box;
    outline: none!important;
}

.mobile_quiz_col_auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    box-sizing: border-box;
    outline: none!important;
}

.mobile_quiz_col_2_left {
    display: flex;
    justify-content: flex-start;
    align-self: center;
}

.mobile_quiz_col_2_right {
    display: flex;
    justify-content: flex-end;
}

.mobile_quiz_p_0 {
    padding: 0!important;  
}

.mobile_quiz_back_href {
    position: relative;
    top: auto!important;
    right: auto!important;
    left: auto!important;
    font-size: 1.6rem;
    line-height: 1!important;
    font-weight: 600;
    color: #161a1f;
    height: auto!important;
    margin-top: auto!important;
    width: auto!important;
    display: flex!important;
    z-index: 10;
    cursor: pointer;
    text-decoration: none;
    background-color: transparent;
    box-sizing: border-box;
    outline: none!important;
    justify-content: center;
    align-items: center;
}
.mobile_quiz_back_href:hover {
  background: #efeeee80;
  border-radius: 5px;
}

.mobile_quiz_navbar_brand {
    max-width: 18rem;
    width: 100%;
    height: auto;
    align-items: center!important;
    display: inline-flex!important;
    padding: 0!important;
    margin: 0!important;
    box-sizing: border-box;
    outline: none!important;
}

.mobile_quiz_brand_href {
    align-items: center!important;
    display: inline-flex!important;
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    transition: all 300ms ease-in-out;
    box-sizing: border-box;
    outline: none!important;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: inherit;
    white-space: nowrap;
}

.mobile_quiz_step_count {
    font-size: 1.6rem;
    line-height: 1!important;
    font-weight: 600;
    color: #161a1f;
    opacity: 1;
    justify-content: flex-end!important;
    display: flex!important;
    box-sizing: border-box;
    outline: none!important;
}

.mobile_quiz_div_fadeOut{
    opacity:0;
    transition: opacity 1s;

}
.quiz_div_fadeIn{
    opacity:1;
    transition: opacity 1.5s;

}

.mobile_check_div_fadeOut{
  opacity:0;
  transition: opacity 0.3s;

}
.mobile_check_div_fadeIn{
  opacity:1;
  transition: opacity 0.3s;

}

.mobile_progress_div {
    width: 100%!important;
}

/* ///////////////////////////////////////// mobile ///////////////////////// */

.quiz_list_container {
  /* max-width: 500px; */
  min-width: 250px;
  background: #ffffff;
  border-radius: 4px;
  margin: 0px auto;
  /* padding: 30px 60px; */
  /* display: flex; */
  display: grid;
  grid: none;
  align-items: center;
  background: transparent;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.quiz_list_container h2 {
    text-align: center;
  /* font-size: 20px;
  font-weight: 500;
  margin: 0; */

  font-size: 1.8rem;
    color: #161a1f;
    line-height: 1.4;
    font-weight: 600;
    margin: 0 0 0 0;
}

.quiz_list_container ul {
  margin-top: 20px;
  margin-left: -40px;
}

.product_div {
  text-align: center;
  text-decoration: none;
  list-style: none;
  
  font-size: 20px;
  /* background: #ffffff; */
  /* background: #007bff;
  color: white; */
  background-color: #ffffff;
  color: #007bff;
  /* border: 1px solid #eaeaea; */
  border-radius: 16px;
  padding: 10px 10px;
  margin-top: 20px;
  cursor: pointer;
  /* border: 1px solid transparent; */
  border: 1px solid #007bff;
  transition: all 350ms ease-in-out;
}

.product_div:hover {
  background: #007bff;
  color: white;
  /* background-color: #ffffff;
  color: #007bff; */
  /* border: 1px solid #007bff; */
}

.quiz_list_container ul li {
  text-decoration: none;
  list-style: none;
  color: #2d264b;
  font-size: 16px;
  /* background: #ffffff; */
  background: #f7f7f7;
  /* border: 1px solid #eaeaea; */
  border-radius: 16px;
  padding: 30px 40px;
  margin-top: 20px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 350ms ease-in-out;
}

.quiz_list_container ul li:hover {
    border: 1px solid #8cbeeb;
}

.quiz_list_container ul .selected-answer {
    background: #fff;
    /* border: 1px solid #1363f5; */
    border: 1px solid #8cbeeb;
    box-shadow: 0 2px 6px #162a410f, 0 12px 24px #07152514;
}


.quiz_list_container .choice_div {
    width: 500px;
    max-width: 500px;
    margin: 0 auto;
}

.quiz_list_header {
    /* position: fixed!important; */
    z-index: 10!important;
    top: 0;
    right: 0;
    left: 0;
    background: #fff;
    /* height: 4.2rem; */
    box-shadow: 0px 7px 31px 0px rgba(0, 0, 0, 0.03);
    align-items: center!important;
    display: flex!important;
    flex-direction: column;
    box-sizing: border-box;
    outline: none!important;
}

.quiz_list_header_container {
    align-items: center!important;
    justify-content: space-between!important;
    display: flex!important;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 117rem!important;
    box-sizing: border-box;
    outline: none!important;
}

.quiz_col_auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    box-sizing: border-box;
    outline: none!important;
}

.quiz_p_0 {
    padding: 0!important;  
}

.quiz_list_navbar_brand {
    max-width: 18rem;
    width: 100%;
    height: auto;
    align-items: center!important;
    display: inline-flex!important;
    padding: 0!important;
    margin: 0!important;
    box-sizing: border-box;
    outline: none!important;
}

.quiz_list_brand_href {
    align-items: center!important;
    display: inline-flex!important;
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    transition: all 300ms ease-in-out;
    box-sizing: border-box;
    outline: none!important;
    cursor: pointer;
    font-size: 2.25rem;
    font-weight: 600;
    line-height: inherit;
    white-space: nowrap;
}

.quiz_list_div_fadeOut{
    opacity:0;
    transition: opacity 0.5s;

}
.quiz_list_div_fadeIn{
    opacity:1;
    transition: opacity 0.5s;

}

.mobile_quiz_list_container {
  margin: 0px auto;
  display: grid;
  align-items: center;
  background: transparent;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.mobile_quiz_list_container h2 {
    text-align: center;
    font-size: 1.8rem;
    color: #161a1f;
    line-height: 1.4;
    font-weight: 600;
    margin: 0 0 0 0;
}

.mobile_quiz_list_container ul {
  margin-top: 20px;
  margin-left: -40px;
}

.mobile_product_div {
  text-align: center;
  text-decoration: none;
  list-style: none;
  
  font-size: 20px;
  /* background: #ffffff; */
  /* background: #007bff;
  color: white; */
  background-color: #ffffff;
  color: #007bff;
  /* border: 1px solid #eaeaea; */
  border-radius: 16px;
  padding: 10px 10px;
  margin-top: 20px;
  cursor: pointer;
  /* border: 1px solid transparent; */
  border: 1px solid #007bff;
  transition: all 350ms ease-in-out;
}

.mobile_product_div:hover {
  background: #007bff;
  color: white;
  /* background-color: #ffffff;
  color: #007bff; */
  /* border: 1px solid #007bff; */
}

.mobile_quiz_list_container ul li {
  text-decoration: none;
  list-style: none;
  color: #2d264b;
  font-size: 16px;
  /* background: #ffffff; */
  background: #f7f7f7;
  /* border: 1px solid #eaeaea; */
  border-radius: 16px;
  padding: 30px 40px;
  margin-top: 20px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 350ms ease-in-out;
}

.mobile_quiz_list_container ul li:hover {
    border: 1px solid #8cbeeb;
}

.mobile_quiz_list_container ul .selected-answer {
    background: #fff;
    /* border: 1px solid #1363f5; */
    border: 1px solid #8cbeeb;
    box-shadow: 0 2px 6px #162a410f, 0 12px 24px #07152514;
}


.mobile_quiz_list_container .mobile_choice_div {
    width: 500px;
    max-width: 500px;
    margin: 0 auto;
}

.mobile_quiz_list_header {
    /* position: fixed!important; */
    z-index: 10!important;
    top: 0;
    right: 0;
    left: 0;
    background: #fff;
    /* height: 4.2rem; */
    box-shadow: 0px 7px 31px 0px rgba(0, 0, 0, 0.03);
    align-items: center!important;
    display: flex!important;
    flex-direction: column;
    box-sizing: border-box;
    outline: none!important;
}

.mobile_quiz_list_header_container {
    align-items: center!important;
    justify-content: space-between!important;
    display: flex!important;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 117rem!important;
    box-sizing: border-box;
    outline: none!important;
}

.mobile_quiz_col_auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    box-sizing: border-box;
    outline: none!important;
}

.mobile_quiz_p_0 {
    padding: 0!important;  
}

.mobile_quiz_list_navbar_brand {
    max-width: 18rem;
    width: 100%;
    height: auto;
    align-items: center!important;
    display: inline-flex!important;
    padding: 0!important;
    margin: 0!important;
    box-sizing: border-box;
    outline: none!important;
}

.mobile_quiz_list_brand_href {
    align-items: center!important;
    display: inline-flex!important;
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    transition: all 300ms ease-in-out;
    box-sizing: border-box;
    outline: none!important;
    cursor: pointer;
    /* font-size: 2.25rem; */
    font-size: 1.5rem;
    font-weight: 600;
    line-height: inherit;
    white-space: nowrap;
}

.mobile_quiz_list_div_fadeOut{
    opacity:0;
    transition: opacity 0.5s;

}
.mobile_quiz_list_div_fadeIn{
    opacity:1;
    transition: opacity 0.5s;

}

.sameline_title_name {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.quiz_content_item_div {
    border: 1px solid #c0c2c3a3;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 2px 6px #162a410f, 0 12px 24px #07152514;
    transition: all 150ms ease-in-out;
    margin-bottom: 5px!important;
}

.quiz_content_item_div:hover {
    /* border-color: #075dfc5e!important; */
    box-shadow: 0 2px 6px rgb(190 195 199 / 83%), 0 12px 24px rgb(66 70 75 / 82%);
}

.quiz_content_item_title {
    /* font-size: 16px; */
    font-weight: 600;
}

.quiz_content_item_text {
    overflow-wrap: anywhere;
}

.quiz_child_div {
    padding: 10px 15px!important;
    border: 1px #ebe7e7 solid!important;
    border-radius: 5px!important;
    margin-bottom: 10px;
    transition: all 150ms ease-in-out;
}

.quiz_child_div:hover {
    /* border-color: #075dfc5e!important; */
    box-shadow: 0 2px 6px rgb(190 195 199 / 83%), 0 12px 24px rgb(66 70 75 / 82%);
 }
