@import url('https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap');

.mobile_quiz-container {
    min-width: 250px;
    background: #ffffff;
    border-radius: 4px;
    margin: 10px auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: transparent;
}

.mobile_active-question-no {
  font-size: 22px;
  font-weight: 500;
  color: #1c6af2;
}

.mobile_total-question {
  font-size: 16px;
  font-weight: 500;
  color: #e0dee3;
}

.mobile_quiz-container h2 {
  text-align: center;
  font-size: 1.6rem;
  color: #161a1f;
  line-height: 1.4;
  font-weight: 600;
  margin: 0 0 0 0;
}

.mobile_quiz-container ul {
  margin-top: 20px;
  margin-left: -40px;
}

.mobile_quiz-container ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;

  text-decoration: none;
  list-style: none;
  color: #2d264b;
  /* font-size: 16px; */
  font-size: 1.3rem;
  /* background: #ffffff; */
  background: #f7f7f7;
  /* border: 1px solid #eaeaea; */
  border-radius: 16px;
  padding: 30px 40px;
  margin-top: 20px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 350ms ease-in-out;
}

.mobile_quiz-container ul li:hover {
    border: 1px solid #8cbeeb;
}

.mobile_quiz-container ul .mobile_selected-answer {
    background: #fff;
    /* border: 1px solid #1363f5; */
    border: 1px solid #8cbeeb;
    box-shadow: 0 2px 6px #162a410f, 0 12px 24px #07152514;
}

.mobile_quiz-container button {
  background: linear-gradient(90.04deg, #1464f5 0.03%, #78aedd 99.96%);
  border-radius: 9px;
  font-size: 18px;
  color: #ffffff;
  padding: 10px 42px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 15px;
}

.mobile_quiz-container button:disabled {
  background: #e7e8e9;
  color: #9fa3a9;
  cursor: not-allowed;
}

.mobile_quiz-container .mobile_choice_div {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

.mobile_flex-right {
  display: flex;
  justify-content: center;
}

.mobile_result h3 {
  font-size: 22px;
  letter-spacing: 1.4px;
  text-align: center;
  margin: 10px 0px 0px!important;
}

.mobile_result p {
  font-size: 22px;
  font-weight: 500;
  text-align: start;
}

.mobile_result .mobile_question_div span {
  color: #131414;
  font-size: 18px;
  font-weight: 600;
}

.mobile_result .mobile_answer_div span {
 
  font-size: 18px;
  color: #1c6af2;
  padding: 0 0 0 15px;
}


.mobile_quiz_header {
    /* position: fixed!important; */
    z-index: 10!important;
    top: 0;
    right: 0;
    left: 0;
    background: #fff;
    /* height: 4.2rem; */
    box-shadow: 0px 7px 31px 0px rgba(0, 0, 0, 0.03);
    align-items: center!important;
    display: flex!important;
    flex-direction: column;
    box-sizing: border-box;
    outline: none!important;
}

.mobile_quiz_header_container {
    align-items: center!important;
    justify-content: space-between!important;
    display: flex!important;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 117rem!important;
    box-sizing: border-box;
    outline: none!important;
}

.mobile_quiz_col_2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    box-sizing: border-box;
    outline: none!important;
}

.mobile_quiz_col_auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    box-sizing: border-box;
    outline: none!important;
}

.mobile_quiz_col_2_left {
    display: flex;
    justify-content: flex-start;
    align-self: center;
}

.mobile_quiz_col_2_right {
    display: flex;
    justify-content: flex-end;
}

.mobile_quiz_p_0 {
    padding: 0!important;  
}

.mobile_quiz_back_href {
    position: relative;
    top: auto!important;
    right: auto!important;
    left: auto!important;
    font-size: 1.6rem;
    line-height: 1!important;
    font-weight: 600;
    color: #161a1f;
    height: auto!important;
    margin-top: auto!important;
    width: auto!important;
    display: flex!important;
    z-index: 10;
    cursor: pointer;
    text-decoration: none;
    background-color: transparent;
    box-sizing: border-box;
    outline: none!important;
    justify-content: center;
    align-items: center;
}
.mobile_quiz_back_href:hover {
  background: #efeeee80;
  border-radius: 5px;
}

.mobile_quiz_navbar_brand {
    max-width: 18rem;
    width: 100%;
    height: auto;
    align-items: center!important;
    display: inline-flex!important;
    padding: 0!important;
    margin: 0!important;
    box-sizing: border-box;
    outline: none!important;
}

.mobile_quiz_brand_href {
    align-items: center!important;
    display: inline-flex!important;
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    transition: all 300ms ease-in-out;
    box-sizing: border-box;
    outline: none!important;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: inherit;
    white-space: nowrap;
}

.mobile_quiz_step_count {
    font-size: 1.6rem;
    line-height: 1!important;
    font-weight: 600;
    color: #161a1f;
    opacity: 1;
    justify-content: flex-end!important;
    display: flex!important;
    box-sizing: border-box;
    outline: none!important;
}

.mobile_quiz_div_fadeOut{
    opacity:0;
    transition: opacity 1s;

}
.quiz_div_fadeIn{
    opacity:1;
    transition: opacity 1.5s;

}

.mobile_check_div_fadeOut{
  opacity:0;
  transition: opacity 0.3s;

}
.mobile_check_div_fadeIn{
  opacity:1;
  transition: opacity 0.3s;

}

.mobile_progress_div {
    width: 100%!important;
}

/* ///////////////////////////////////////// mobile ///////////////////////// */
