@import url('https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap');

body {
  font-family: 'Anek Malayalam', sans-serif;
  /* background: linear-gradient(90.04deg, #800080 0.03%, #ffc0cb 99.96%);
  color: #11052c;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0 30px;  */
}

.quiz-container {
  width: 50% !important;
  /* max-width: 500px; */
  min-width: 250px;
  background: #ffffff;
  border-radius: 4px;
  margin: 50px auto;
  padding: 100px 60px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: transparent;
}

.quiz-container1 {
  @media (min-width: 800px) {
    width: 800px;
    min-width: 250px;
    background: #ffffff;
    border-radius: 4px;
    margin: 50px auto;
    padding: 100px 60px;
    display: flex;
    flex-direction: column;
    background: transparent;
  }

  background: #ffffff;
  border-radius: 4px;
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
  background: transparent;
}

.parent_container {
  display: flex;
  justify-content: space-between;
  height: 98vh;
}

.parent_container img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.quiz-img-container {
  width: 50%;
  padding: 50px 0 0 0;
}

/* .quiz-container .active-question-no { */
.active-question-no {
  font-size: 32px;
  font-weight: 500;
  color: #1c6af2;
}

/* .quiz-container .total-question { */
.total-question {
  font-size: 16px;
  font-weight: 500;
  color: #e0dee3;
}

.quiz-container h2 {
  text-align: center;
  font-size: 1.8rem;
  color: #161a1f;
  line-height: 1.4;
  font-weight: 600;
  margin: 0 0 0 0;
}

.quiz-container1 h2 {
  /* text-align: center; */
  @media (min-width: 800px) {
    font-size: 1.8rem;
    color: #161a1f;
    line-height: 1.4;
    font-weight: 500;
    margin: 0 0 0 0;
  }

  font-size: 1.5rem;
  color: #161a1f;
  line-height: 1.3;
  font-weight: 500;
  margin: 0 0 0 0;
}

.quiz-container1 h4 {
  @media (min-width: 800px) {
    font-size: 1.6rem;
    color: #161a1f9c;
    line-height: 1.4;
    font-weight: 500;
    margin: 10px 0 0 0;
  }
  font-size: 1.3rem;
  color: #161a1f9c;
  line-height: 1.2;
  font-weight: 500;
  margin: 10px 0 0 0;
}

.quiz-container ul {
  margin-top: 20px;
  margin-left: -40px;
}

.quiz-container1 ul {
  margin-top: 20px;
  margin-left: -40px;
}

.quiz-container ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;

  text-decoration: none;
  list-style: none;
  color: #2d264b;
  /* font-size: 16px; */
  font-size: 1.3rem;
  /* background: #ffffff; */
  background: #f7f7f7;
  /* border: 1px solid #eaeaea; */
  border-radius: 16px;
  padding: 30px 40px;
  margin-top: 20px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 350ms ease-in-out;
}

.quiz-container1 ul li {
  @media (min-width: 800px) {
    font-size: 1.3rem;
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;

  text-decoration: none;
  list-style: none;
  color: #2d264b;
  /* font-size: 16px; */
  /* font-size: 1.3rem; */
  /* background: #ffffff; */
  background: #8ebaf133;
  /* border: 1px solid #eaeaea; */
  border-radius: 5px;
  padding: 25px 25px;
  margin-top: 20px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 350ms ease-in-out;
}

.quiz-container1 ul li .txt_index {
  border: 2px solid #0571f782;
  padding: 8px 12px;
  line-height: 1;
  text-align: center;
  border-radius: 5px;
  background: white;
}

.quiz-container ul li:hover {
  border: 1px solid #8cbeeb;
}
.quiz-container1 ul li:hover {
  border: 1px solid #0089ff;
}

.quiz-container ul .selected-answer {
  background: #fff;
  /* border: 1px solid #1363f5; */
  border: 1px solid #8cbeeb;
  box-shadow: 0 2px 6px #162a410f, 0 12px 24px #07152514;
}
.quiz-container1 ul .selected-answer {
  background: #fff;
  /* border: 1px solid #1363f5; */
  border: 1px solid #8cbeeb;
  box-shadow: 0 2px 6px #162a410f, 0 12px 24px #07152514;
}

.quiz-container button {
  background: linear-gradient(90.04deg, #1464f5 0.03%, #78aedd 99.96%);
  border-radius: 9px;
  font-size: 18px;
  color: #ffffff;
  padding: 10px 42px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 15px;
}
.quiz-container1 button {
  background: linear-gradient(90.04deg, #1464f5 0.03%, #78aedd 99.96%);
  border-radius: 9px;
  font-size: 18px;
  color: #ffffff;
  padding: 10px 42px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 15px;
}

.quiz-container button:disabled {
  background: #e7e8e9;
  color: #9fa3a9;
  cursor: not-allowed;
}
.quiz-container1 button:disabled {
  background: #e7e8e9;
  color: #9fa3a9;
  cursor: not-allowed;
}

.quiz-container .choice_div {
  width: 500px;
  max-width: 500px;
  margin: 0 auto;
}
.quiz-container1 .choice_div {
  @media (min-width: 800px) {
    width: 500px;
    /* width: 100%; */
    max-width: 500px;
    margin: 45px 0 0 0;
  }
  /* width: 500px; */
  /* width: 100%; */
  max-width: 500px;
  margin: 0 0 0 0;
}

.flex-right {
  display: flex;
  justify-content: center;
}
.result {
  padding: 50px 0 0 0;
}
.result h3 {
  font-size: 24px;
  letter-spacing: 1.4px;
  text-align: center;
  margin: 18px 0px !important;
}

.result p {
  font-size: 22px;
  font-weight: 500;
  text-align: start;
}

.result .question_div span {
  color: #131414;
  font-size: 18px;
  font-weight: 600;
}

.result .answer_div span {
  font-size: 18px;
  color: #1c6af2;
  padding: 0 0 0 15px;
}

.quiz_header {
  position: fixed !important;
  z-index: 10 !important;
  top: 0;
  right: 0;
  left: 0;
  background: #fff;
  /* height: 4.2rem; */
  box-shadow: 0px 7px 31px 0px rgba(0, 0, 0, 0.03);
  align-items: center !important;
  display: flex !important;
  flex-direction: column;
  box-sizing: border-box;
  outline: none !important;
}

.quiz_header_container {
  align-items: center !important;
  justify-content: space-between !important;
  display: flex !important;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 117rem !important;
  box-sizing: border-box;
  outline: none !important;
}

.quiz_col_2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  box-sizing: border-box;
  outline: none !important;
}

.quiz_col_auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  box-sizing: border-box;
  outline: none !important;
}

.quiz_col_2_left {
  display: flex;
  justify-content: flex-end;
  align-self: center;
}

.quiz_col_2_right {
  display: flex;
  justify-content: flex-start;
}

.quiz_p_0 {
  padding: 0 !important;
}

.quiz_back_href {
  position: relative;
  top: auto !important;
  right: auto !important;
  left: auto !important;
  font-size: 1.6rem;
  line-height: 1 !important;
  font-weight: 600;
  color: #161a1f;
  height: auto !important;
  margin-top: auto !important;
  width: auto !important;
  display: flex !important;
  z-index: 10;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  box-sizing: border-box;
  outline: none !important;
  justify-content: center;
  align-items: center;
}
.quiz_back_href:hover {
  background: #efeeee80;
  border-radius: 5px;
}

.quiz_navbar_brand {
  max-width: 18rem;
  width: 100%;
  height: auto;
  align-items: center !important;
  display: inline-flex !important;
  padding: 0 !important;
  margin: 0 !important;
  box-sizing: border-box;
  outline: none !important;
}

.quiz_brand_href {
  @media (min-width: 800px) {
    align-items: center !important;
    display: inline-flex !important;
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    transition: all 300ms ease-in-out;
    box-sizing: border-box;
    outline: none !important;
    cursor: pointer;
    font-size: 2.25rem;
    font-weight: 600;
    line-height: inherit;
    white-space: nowrap;
  }

  @media (max-width: 800px) {
    align-items: center !important;
    display: inline-flex !important;
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    transition: all 300ms ease-in-out;
    box-sizing: border-box;
    outline: none !important;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: inherit;
    white-space: nowrap;
  }
}

.quiz_step_count {
  font-size: 1.6rem;
  line-height: 1 !important;
  font-weight: 600;
  color: #161a1f;
  opacity: 1;
  justify-content: flex-end !important;
  display: flex !important;
  box-sizing: border-box;
  outline: none !important;
}

.quiz_div_fadeOut {
  opacity: 0;
  transition: opacity 1s;
}
.quiz_div_fadeIn {
  opacity: 1;
  transition: opacity 1.5s;
}

.check_div_fadeOut {
  opacity: 0;
  transition: opacity 0.3s;
}
.check_div_fadeIn {
  opacity: 1;
  transition: opacity 0.3s;
}

.progress_div {
  width: 100% !important;
}

/* ///////////////////////////////////////// mobile ///////////////////////// */
