@import url('https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap');

.quiz_list_container {
  /* max-width: 500px; */
  min-width: 250px;
  background: #ffffff;
  border-radius: 4px;
  margin: 0px auto;
  /* padding: 30px 60px; */
  /* display: flex; */
  display: grid;
  grid: none;
  align-items: center;
  background: transparent;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.quiz_list_container h2 {
    text-align: center;
  /* font-size: 20px;
  font-weight: 500;
  margin: 0; */

  font-size: 1.8rem;
    color: #161a1f;
    line-height: 1.4;
    font-weight: 600;
    margin: 0 0 0 0;
}

.quiz_list_container ul {
  margin-top: 20px;
  margin-left: -40px;
}

.product_div {
  text-align: center;
  text-decoration: none;
  list-style: none;
  
  font-size: 20px;
  /* background: #ffffff; */
  /* background: #007bff;
  color: white; */
  background-color: #ffffff;
  color: #007bff;
  /* border: 1px solid #eaeaea; */
  border-radius: 16px;
  padding: 10px 10px;
  margin-top: 20px;
  cursor: pointer;
  /* border: 1px solid transparent; */
  border: 1px solid #007bff;
  transition: all 350ms ease-in-out;
}

.product_div:hover {
  background: #007bff;
  color: white;
  /* background-color: #ffffff;
  color: #007bff; */
  /* border: 1px solid #007bff; */
}

.quiz_list_container ul li {
  text-decoration: none;
  list-style: none;
  color: #2d264b;
  font-size: 16px;
  /* background: #ffffff; */
  background: #f7f7f7;
  /* border: 1px solid #eaeaea; */
  border-radius: 16px;
  padding: 30px 40px;
  margin-top: 20px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 350ms ease-in-out;
}

.quiz_list_container ul li:hover {
    border: 1px solid #8cbeeb;
}

.quiz_list_container ul .selected-answer {
    background: #fff;
    /* border: 1px solid #1363f5; */
    border: 1px solid #8cbeeb;
    box-shadow: 0 2px 6px #162a410f, 0 12px 24px #07152514;
}


.quiz_list_container .choice_div {
    width: 500px;
    max-width: 500px;
    margin: 0 auto;
}

.quiz_list_header {
    /* position: fixed!important; */
    z-index: 10!important;
    top: 0;
    right: 0;
    left: 0;
    background: #fff;
    /* height: 4.2rem; */
    box-shadow: 0px 7px 31px 0px rgba(0, 0, 0, 0.03);
    align-items: center!important;
    display: flex!important;
    flex-direction: column;
    box-sizing: border-box;
    outline: none!important;
}

.quiz_list_header_container {
    align-items: center!important;
    justify-content: space-between!important;
    display: flex!important;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 117rem!important;
    box-sizing: border-box;
    outline: none!important;
}

.quiz_col_auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    box-sizing: border-box;
    outline: none!important;
}

.quiz_p_0 {
    padding: 0!important;  
}

.quiz_list_navbar_brand {
    max-width: 18rem;
    width: 100%;
    height: auto;
    align-items: center!important;
    display: inline-flex!important;
    padding: 0!important;
    margin: 0!important;
    box-sizing: border-box;
    outline: none!important;
}

.quiz_list_brand_href {
    align-items: center!important;
    display: inline-flex!important;
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    transition: all 300ms ease-in-out;
    box-sizing: border-box;
    outline: none!important;
    cursor: pointer;
    font-size: 2.25rem;
    font-weight: 600;
    line-height: inherit;
    white-space: nowrap;
}

.quiz_list_div_fadeOut{
    opacity:0;
    transition: opacity 0.5s;

}
.quiz_list_div_fadeIn{
    opacity:1;
    transition: opacity 0.5s;

}
